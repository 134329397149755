import cookie from 'react-cookies';


//
//
const authStatus = () => {
  let init = cookie.load('Auth');
  if(init){
    return {
      type: "AUTHSTATUS",
      user: init.user,
      role: init.role
    }
  }else{
    return {
      type: "AUTHSTATUS",
      user: null
    }
  }
}


//
//
const authUpdate = (data) => {
  cookie.save('Auth', JSON.stringify(data), { path: '/' });
  return {
    type: "AUTHUPDATE",
    user: data.user,
    role: data.role
  }
}


//
//
export default {
  authStatus,
  authUpdate
}
