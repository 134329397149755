// Staging

export const ApiUrl = 'https://proukdev.omniapps.info/wp-json/api';
export const MiddlewareApiUrl = 'https://middleware.omniapps.info';
export const AuthUrl = 'https://dev-sso.dermalogica.co.uk/oxauth/restv1/authorize?response_type=code&scope=openid%20profile%20permission%20email%20user_name&client_id=6fe48054-d68b-4746-8c79-f0613a62a9e8&state=00753c6931192e104f4d663bebe7f45f&redirect_uri=https%3A%2F%2Fproukdev.omniapps.info%2Fwp-admin%2Fadmin-ajax.php%3Faction%3Dopenid-connect-authorize'

export const SITENAME = 'Dermalogica PRO Dashboard';
export const SITEURL = 'https://localhost:1234';
export const DateFormat = 'DD MMM. YYYY HH:mm';

export const ProUrl = 'https://proukdev.omniapps.info';
export const LogoutUrl = 'https://proukdev.omniapps.info/wp-login.php?action=logout';
export const ViewBusinessRecordCard = 'https://service.dermalogica.com/BusinessRecordCard/BRC_CS.aspx?reset=1';


export const navItems = [
  {title: 'My dashboard', link:'/', role:[4,5,6,7,8,9,10,11]},
  {title: 'My business resources', link:'/resources', role:[4,5,6,7,8,9], or:'salonservices'},
  {title: 'Manage employees', link:'/employees', role:[5,6], or:'owner_rights'},
  // {title: 'Business Record Card', link:'https://service.dermalogica.com/BusinessRecordCard/BRC_CS.aspx', role:[5,6]},
  {title: 'My education', link:'/education', role:[4,5,6,7,8,9,10,11]}
]
