import axios from 'axios';
import { ApiUrl } from '../configuration.js';


export const API = (headers={}, domain=null) => {

  if(!domain){
    domain = ApiUrl
  }

  let headersConfig = {
    'Content-Type': 'application/json',
    ...headers
  };

  let axiosConfig = {
    baseURL: domain,
    headers: headersConfig
  }

  // axios.defaults.withCredentials = true

  return axios.create(axiosConfig);
}
