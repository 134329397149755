const auth = (state = {}, action) => {
  switch(action.type){
    case "AUTHSTATUS":
      return {
        ...state,
        user: action.user,
        role: action.role
      }
    case "AUTHUPDATE":
      return {
        ...state,
        user: action.user || state.user,
        role: action.role || state.role
      }
    default:
      return state
  }
}

export default auth;
