import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

// controllers
import { API } from './Api'
import { _localRole } from './Roles'
import { MiddlewareApiUrl, AuthUrl, ProUrl } from '../configuration.js'
import _actions from './redux/actions'

// compnents
import Loading from '../components/Loading';


function ErrorMessage() {
  return (
    <div className="container py-5 px-3 text-center d-flex flex-column align-items-center">
      <h3>You are not authorized to access this application.<br/>Please click here to register</h3>
      <a href={ProUrl+'/create-login'} className="button radius w-200 mt-4">Create Login</a>
    </div>
  )
}


export default function Authentication(props) {

  const dispatch = useDispatch()
  const auth = dispatch(_actions.auth.authStatus());
  const [ isAuthorized, setAuthorized ] = useState(false);
  const [ isError, setIsError ] = useState(false);


  //
  //
  const _additionalCheck = (data) => {
    //Account active is completely different and its tied to our ERP System….
    //So check if “active” flag is “Y” then allow them to access the pro & Student dashboard.
    //If “account_active” is “Y” then allow them to access to “business dashboard”
    // API({}, MiddlewareApiUrl)
    // .post('/ebusinesscenter/authorize', { email: data.user.user_email })
    // .then(response => {
    //   if(response.data && response.data['active'])
    //   {

    //     const role = _localRole(response.data);
    //     dispatch(_actions.auth.authUpdate({user:response.data, role:role}));
    //     setAuthorized(true);

    //   }else{

    //     setIsError(true);

    //   }

    //   // if(
    //   //   (response.data && response.data['account_active'] && response.data['account_active']==='Y') ||
    //   //   (response.data && response.data['active'] && response.data['active']==='Y'))
    //   //   {

    //   //   const role = _localRole(response.data);
    //   //   dispatch(_actions.auth.authUpdate({user:response.data, role:role}));
    //   //   setAuthorized(true);

    //   // }else{

    //   //   setIsError(true);

    //   // }
    // }).catch(error => {
    //   window.location.href = AuthUrl
    // });


    console.log('Authentication #1',data)
    console.log('Authentication #2',auth)
    dispatch(_actions.auth.authUpdate({user:{...data.user, first_name:data.user.user_nicename}}));
    setAuthorized(true);

  }



  //
  //
  const _getuserInfo = async() => {

    // Local data

    // 33902 - No award
    // 41523 - Blue
    // 36142 - Derm Circle
    // 38210 - VIP
    // 51289 - Ambassador

    // let response = {
    //   "apps": [
    //     {
    //       "app_name": "BRC",
    //       "app_access": "Y"
    //     }
    //   ],
    //   "city": "3333",
    //   "role": "OW",
    //   "term": "Y",
    //   "state": "AR",
    //   "active": "Y",
    //   "status": null,
    //   "userid": 52399,
    //   "message": null,
    //   "zipcode": "33333",
    //   "last_name": "Test",
    //   "account_no": 36142,
    //   "is_buyer": "Y",
    //   "created_by": "dermalogicapro",
    //   "first_name": "Abdulff",
    //   "national_account": "N",
    //   "updated_by": "dermalogicapro",
    //   "created_date": "2021-02-08T12:44:16.647",
    //   "owner_rights": "Y",
    //   "phone_number": "2312312",
    //   "updated_date": "2021-02-09T00:34:43.313",
    //   "email_address": "emir@omnitask.ba",
    //   "account_active": "Y",
    //   "access_code": "salonservices",
    //   "street_address": "Obala",
    //   "account_name": "Sales Test Acct. Do Not Ship"
    // }
    // const role = _localRole(response);
    // dispatch(_actions.auth.authUpdate({user:response, role:role}));
    // setAuthorized(true);
    // end Local data

    // Live data
    return API()
    .get('/userinfo', {withCredentials: true})
    .then(response => {
      if(response.data){
        _additionalCheck({user:{...response.data.data, ...response.data.allcaps}})
      }else{
        window.location.href = AuthUrl
      }
    }).catch(error => {
      window.location.href = AuthUrl
    });
    // end Live data

  }


  //
  //
  useEffect(()=>{

    _getuserInfo()

  },[])


  //
  //
  return (
    isAuthorized ? props.children : isError ? <ErrorMessage/> : <Loading/>
  );
}
