import React, { Suspense, useEffect, useState, useRef } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// controllers
import _actions from './redux/actions'
import { API } from './Api';
import ScrollToTop from './ScrollToTop';


// components
import Loading from '../components/Loading';
import Layout from '../components/Layout';

// pages
import Home from '../pages/Home';
import MyAccount from '../pages/MyAccount';
import Resources from '../pages/Resources';
import Education from '../pages/Education';
import Employees from '../pages/Employees';
import EmployeesUpdate from '../pages/Employees/update';

// import PageGeneric from '../pages/PageGeneric';
import Page404 from '../pages/404';
import Page403 from '../pages/403';


function Router() {

  const dispatch = useDispatch();
  const mounted = useRef(null);
  const [ _footer, setFooter] = useState(null);
  const [ _navigation, setNavigation] = useState(null);

  const [ sidebar, setSidebar ] = useState(false);


  // //
  // //
  // const _getFooter = () => {
  //
  //   API()
  //   .get('/settings/footer')
  //   .then(response => {
  //     setFooter(response.data);
  //   }).catch(error => {
  //     console.log(error);
  //   });
  //
  // }


  //
  //
  const _getNavigation = () => {

    API()
    .get('/settings/navigation')
    .then(response => {
      // console.log(response);
      setNavigation(response.data);
    }).catch(error => {
      console.log(error);
    });

  }


  //
  //
  const _sidebar = (state) => {
    setSidebar(state);
  }


  //
  // init
  // useEffect(() => {
  //   // _getFooter();
  //   _getNavigation();
  // },[]);


  //
  // init
  // useEffect(() => {
  //   mounted.current = true;

  //   if(mounted.current){
  //     if(_navigation){
  //     // if(_navigation && _footer){
  //       dispatch(_actions.init.initUpdate(
  //         {
  //           navigation: _navigation,
  //           footer: _footer
  //         }
  //       ))
  //     }else{
  //       _getNavigation();
  //     }
  //   }

  //   return () => mounted.current = false;
  // },[_navigation, _footer]);


  return (
    <BrowserRouter basename={'/'}>
      <ScrollToTop />
      <Suspense fallback={<Loading size="large" type="full"/>}>
        <Layout sidebar={sidebar}>
          <Switch>

            <Route exact path="/">
              <Home sidebar={_sidebar}/>
            </Route>

            {/* <Route exact path="/education">
              <Education sidebar={_sidebar}/>
            </Route>

            <Route exact path="/resources">
              <Resources sidebar={_sidebar}/>
            </Route>

            <Route exact path="/employees">
              <Employees sidebar={_sidebar}/>
            </Route>
            <Route exact path="/employees/update">
              <EmployeesUpdate sidebar={_sidebar}/>
            </Route>

            <Route exact path="/my-account">
              <MyAccount sidebar={_sidebar}/>
            </Route>

            <Route exact path="/404">
              <Page404 sidebar={_sidebar}/>
            </Route>
            <Route exact path="/403">
              <Page403 sidebar={_sidebar}/>
            </Route> */}

            <Route path="*">
              <Redirect
                to={{
                  pathname: "/404"
                }}
              />
            </Route>

          </Switch>
          <ToastContainer
            position="top-right"
          />
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;

// <Route exact path="/:permalink">
//   <PageGeneric sidebar={_sidebar}/>
// </Route>
