import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import QueueAnim from 'rc-queue-anim';

// controllers
import { _checkRole } from '../../controllers/Roles'
import { ViewBusinessRecordCard, navItems } from '../../configuration.js'

// custom style
import './style.scss';

// images
import iconBox from '../../assets/images/card-box.svg';


//
//
export default function Sidebar(props) {

  let location = useLocation();
  const init = useSelector(state => state.init);
  const auth = useSelector(state => state.auth);
  const [ navicon, setNavicon ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState('');


  //
  //
  const _items = () =>{
    if(navItems){
      return navItems.map((item,i)=>{
        let isVisible = _checkRole(item.role, auth.role.id)
        if(!isVisible && item.or && item.or==='salonservices' && auth.user.access_code){
          isVisible = auth.user.access_code.includes(item.or) || false
        }
        if(!isVisible && item.or && item.or==='owner_rights'){
          isVisible = auth.user.owner_rights==='Y' || isVisible ? true : false
        }
        return(
          isVisible ?
          <li key={i}>
            <Link to={item.link} onClick={ navicon ? _open : null} className={currentPage===item.link ? 'active' : ''}>
              <img src={iconBox} alt={item.title} />
              {item.title}<i></i>
            </Link>
          </li>
          : null
        )
      })
    }
  }


  //
  //
  useEffect(()=>{
    setCurrentPage(location.pathname);
  },[location])


  //
  //
  const _open = () => {
    props.open(!navicon);
    setNavicon(!navicon);
  }


  //
  //
  return (
    <Fragment>
      <div className="sidebar">
        <QueueAnim type={['left', 'right']} className="ul">

          {/* {_items()}

          {auth.user.apps && auth.user.apps.length>0 && auth.user.apps[0].app_name==='BRC' && auth.user.apps[0].app_access==='Y' ?
          <li>
            <a href={ViewBusinessRecordCard} target="_blank" rel="noopener noreferrer">
              <img src={iconBox} alt={'Business record card'} />
              Business record card
            </a>
          </li>
          : null } */}

        </QueueAnim>
      </div>
    </Fragment>
  );
};
