//
//
const initStatus = () => {
  let init = localStorage.getItem('init');
  if(init){
    let jsonData = JSON.parse(init);
    return {
      type: "INITSTATUS",
      data: jsonData
    }
  }else{
    return {
      type: "INITSTATUS",
      data: null
    }
  }
}


//
//
const initUpdate = (data) => {
  localStorage.setItem('init',JSON.stringify(data));
  return {
    type: "INITUPDATE",
    status: true,
    data: data
  }
}


//
//
export default {
  initStatus,
  initUpdate
}
