import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Link } from 'react-router-dom'

// custom style
import './style.scss';

// variables
import { SITENAME ,ProUrl } from '../../configuration.js';

// images
import logo from '../../assets/images/dermalogica-logo.svg';
//
import iconFacebook from '../../assets/images/facebook.svg';
import iconInstagram from '../../assets/images/instagram.svg';
import iconTwitter from '../../assets/images/twitter.svg';
import iconYoutube from '../../assets/images/youtube.svg';


//
//
export default function Footer(props) {

  const init = useSelector(state => state.init);
  const [ footer, setFooter ] = useState(null);


  //
  //
  useEffect(()=>{
    if(init && init.data){
      setFooter(init.data.footer);
    }
  },[init]);


  //
  //
  return (
    <Fragment>
      <footer className="footer py-4 py-md-5">

        <div className="container py-4">
          <div className="row">
            <div className="col-12 col-md-4 d-flex flex-column order-1 py-4">
              <aside id="custom_html-2" className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">
                  <a className="d-block footer-dermalogica-logo" href={ProUrl}>
                    <img src={logo} alt={SITENAME}/>
                  </a>
                  <a href={'tel:01372363600'} className="d-inline-block phone-num mt-5 mb-4">01372 363600</a>
                  <div className="footer-address d-flex flex-column">
                    <strong>The Manser Building</strong>
                    <span>Leatherhead, Thorncroft Drive,<br/>www.dermalogica.co.uk</span>
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-12 col-md-4 order-3 order-md-2 py-4 offset-md-4 text-left text-md-right">
              <aside id="custom_html-3" className="widget_text widget widget_custom_html">
                <div className="textwidget custom-html-widget">
                  <span className="d-inline-block mb-4 helvetica-bold">stay connected</span>
                  <div className="footer-social-icons">
                    <a className="d-inline-block mr-3" href="https://www.facebook.com/groups/DermProUS" target="_blank" rel="noreferrer">
                      <img src={iconFacebook} alt="Facebook"/>
                    </a>
                    <a className="d-inline-block mr-3" href="https://www.instagram.com/dermalogicapro/" target="_blank" rel="noreferrer">
                      <img src={iconInstagram} alt="Instagram"/>
                    </a>
                    <a className="d-inline-block mr-3" href="https://www.youtube.com/dermalogica" target="_blank" rel="noreferrer">
                      <img src={iconYoutube} alt="Youtube"/>
                    </a>
                    <a className="d-inline-block" href="https://twitter.com/dermalogica" target="_blank" rel="noreferrer">
                      <img src={iconTwitter} alt="Twitter"/>
                    </a>
                  </div>
                  <div className="career-links mt-4 mb-4 pt-4 pb-4">
                    <a href={'https://www.dermalogica.com/pages/privacy-notice'} target="_blank" rel="noreferrer" className="mr-4">Legal</a>
                    <a href={'https://www.dermalogica.com/pages/privacy-query'} target="_blank" rel="noreferrer">Do not sell my info</a>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>

      </footer>
    </Fragment>
  );
};
