import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import QueueAnim from 'rc-queue-anim';
import dayjs from 'dayjs';

// controllers
import _actions from '../../controllers/redux/actions'
import { _checkRole } from '../../controllers/Roles'

// variables
import { MiddlewareApiUrl, ApiUrl, SITENAME, ViewBusinessRecordCard } from '../../configuration.js';

// components
import Loading from '../../components/Loading';
import BlockAnnouncements from '../../components/BlockAnnouncements';
import BlockPromotions from '../../components/BlockPromotions';
import BlockPrograms from '../../components/BlockPrograms';

// images
import pinD from '../../assets/images/pin-d.svg';

// controllers
import { API } from '../../controllers/Api';


export default function Home(props){

  const dispatch = useDispatch()
  const mounted = useRef(null);
  const [ loading, setLoading ] = useState(true);
  const auth = dispatch(_actions.auth.authStatus());

  const [ items, setiItems ] = useState(null);
  const [ smcLinks, setSmclinks ] = useState([])
  const [ pcvLinks, setPcvlinks ] = useState([])



  const _getSmcLinks = () =>{
    API({},ApiUrl)
    .get('/smc-resources')
    .then(response => {
      console.log('_getSmcLinks',response);
      setSmclinks(response.data)
    }).catch(error => {
      console.log(error);
    });
  }

  const _getPcvLinks = () =>{
    API({},ApiUrl)
    .get('/pcv-resources')
    .then(response => {
      console.log('_getSmcLinks',response);
      setPcvlinks(response.data)
    }).catch(error => {
      console.log(error);
    });
  }


  //
  //
  // useEffect(()=>{
  //   if(items && items.loyaltyinfo && items.loyaltyinfo[0] && items.loyaltyinfo[0].awardlevel){
  //     _getSmcLinks()
  //     _getPcvLinks()
  //   }
  // },[items])


  //
  //
  useEffect(()=>{
    mounted.current = true;

    if(mounted.current){
      props.sidebar(true);
      setLoading(false);
    }

    // API({},MiddlewareApiUrl)
    // .get('/ebusinesscenter/get-account-info?accountno='+auth.user.account_no)
    // .then(response => {
    //   if(mounted.current){
    //     // console.log('data',response.data);
    //     setiItems(response.data)
    //     if(response.data.national_account){
    //       // console.log('UPDATE')
    //       dispatch(_actions.auth.authUpdate({...auth, user:{...auth.user, national_account:response.data.national_account}}));
    //     }
    //   }
    // }).catch(error => {
    //   console.log(error);
    // });

    return () => mounted.current = false;
  },[props]);


  //
  //
  const _box1 = () =>{

    const _net = items.loyaltyinfo[0].netsales
    const _max = items.loyaltyinfo[0].next_level_min_threshold
    const isPositive = _net > 0 ? true : false
    const toSpent = isPositive ? (_max - Math.abs(_net)) : (_max + Math.abs(_net))
    const percetn = toSpent>_max ? 0 : ((_net/_max)*100).toFixed(0)

    let message = <p className="text-center">You're getting there! Spend {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(toSpent.toFixed(2))} to reach <strong>{items.loyaltyinfo[0].next_level_award}</strong> status and <u>unlock more perks!</u></p>

    if(_net < items.loyaltyinfo[0].min_threshold){
      const _max2 = items.loyaltyinfo[0].min_threshold
      const toSpent2 = isPositive ? (_max2 - Math.abs(_net)) : (_max2 + Math.abs(_net))
      message = <p className="text-center">You're getting there! Spend {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(toSpent2.toFixed(2))} to maintain your <strong>{items.loyaltyinfo[0].awardleveldesc}</strong> status through {items.loyaltyinfo[0].dd_year}.</p>
    }

    if(_net>100000){
      message = <p className="text-center">Congratulations! You have earned Diamond status through the end of {items.loyaltyinfo[0].dd_year}.</p>
    }

    return(
      <div className="box box1 mb-5 d-flex flex-column justify-content-center align-items-center">
        <h4>loyalty program</h4>
        <div className="my-status d-flex flex-column align-items-center">
          <h5>My status</h5>
          <h1>{items.loyaltyinfo[0].awardlevel  !== '' ? items.loyaltyinfo[0].awardleveldesc : 'Dermalogica Account'}</h1>
        </div>
        <p className='text-center'>2022 Sales: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(items.loyaltyinfo[0].netsales)}</p>
        {message}
        <div className="progress my-4 mt-5">
          <div className="bar">
            <div style={{width:percetn+'%'}}><img src={pinD} alt="Dermalogica PRO"/></div>
          </div>
          <div className="legend">
            <div>$0</div>
            {items.loyaltyinfo[0].awardlevel !== 'AMB' && <div>{items.loyaltyinfo[0].next_level_min_threshold/2}</div>}
            <div>{items.loyaltyinfo[0].next_level_min_threshold}</div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
          <a href="https://pro.dermalogica.com/wp-content/uploads/2022/01/Dermalogica-PST-Loyalty-Program-2022.pdf" target="_blank" className="button dark outline w-200 mx-3 my-1">membership tiers</a>
          <a href="https://buy.dermalogica.com/" target="_blank" className="button dark w-200 mx-3 my-1">order now</a>
        </div>
      </div>
    )
  }


  //
  //
  const _box2 = () =>{

    const showDerm = items && items.dermDollar ? true : false
    // const showPcv  = items && items.pcvinfo && items.pcvinfo[0] && items.pcvinfo[0].show_pcv && items.pcvinfo[0].show_pcv=='Y'
    const showPcv  = items && items.pcvinfo && items.pcvinfo[0]

    console.log('_box2', showDerm, showPcv)

    return(
      items ?
      <div className={`box box2 my-5 d-flex flex-column justify-content-center align-items-center`}>
        <h4>account credits</h4>
        <div className={`row d-flex ${showDerm && showPcv ? 'justify-content-between' : 'justify-content-center'}`}>
          {showDerm && 
          <div className="col-md-6 col-lg-5">
            <div className="circle">
              <div className="my-5">
                <h5>MY DERM</h5>
                <h1><small>$</small>{items.dermDollar.available}</h1>
                <h5 className="pt-2">DOLLARS</h5>
              </div>
              <p className="text-center"><small>For every $50 you spend on Dermalogica products, We contribute 1 Derm Dollar to your personal marketing funds. Derm Dollars may be applied towards your order for samples, testers, and support items.</small></p>
            </div>
          </div>
          }
          {showPcv && 
          <div className="col-md-6 col-lg-5">
            <div className="sectagon">
              <div className="my-5">
                <h5>MY PCV</h5>
                <h1>{items.pcvinfo[0].available}</h1>
                <h5 className="pt-2">AVAILABLE</h5>
              </div>
              <p className="text-center"><small>Products Credit Vouchers can be earned through the affiliate program or earning rewards such as through the loyalty program. PCVs may be applied towards your order for retail, professional, and support items.</small></p>
            </div>
          </div>
          }
        </div>
        
        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-4">
          {items.dermdollar_resources && items.dermdollar_resources.length>0 &&
            items.dermdollar_resources.map((link,i)=>{
              return (
                <a
                  href={link.ddr_i_link}
                  className={i===0 ? 'button dark outline w-200 mx-3 my-1' : 'button dark w-200 mx-3 my-1'}
                  key={i}
                  target={link.ddr_i_open_in_new_tab ? '_blank' : '_self'}
                  rel={link.ddr_i_open_in_new_tab ? 'noopener noreferrer' : ''}
                >{link.ddr_i_title}</a>
              )
            })
          }
        </div>
        <p className="text-center mt-3">
          <small>View</small> <a href={ViewBusinessRecordCard} target="_blank" rel="noopener noreferrer" style={{textDecoration:'underline'}}><small>Business Record Card</small></a>  <small>for Derm Dollar expiration dates.</small><br/><small>Call Customer Service (800)-345-2761 to inquire about PCV expiration dates.</small>
        </p>
      </div>
      : null
    )
  }


  //
  //
  const _box3 = () =>{
    if(items && items.pcvinfo && items.pcvinfo[0]){
      const i = items.pcvinfo[0];
      if(i.show_pcv && i.show_pcv !== 'N'){
        return(
          <div className="box box3 my-5 d-flex flex-column justify-content-center align-items-center">
            <h4>earn a product credit voucher</h4>
            <p className="text-center"><small>
              As part of our <strong>Dermalogica Loyalty Program</strong>, once you grow your 2022 purchases by 20% (vs 2021)<br/> you are eligible to receive 5% back on all of your 2022 purchases in the form of a Product Credit Voucher.
            </small></p>
            <div className="row py-4">
              <div className="col-md-4 text-center">
                <h5>my 2022 dermalogica goal</h5>
                <h1><small>$</small>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(i.pcv_goal.toFixed(2))}</h1>
              </div>
              <div className="col-md-4 text-center">
                <h5>my estiamted pcv earned</h5>
                <h1><small>$</small>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(i.current_year_pcvearned.toFixed(2))}</h1>
              </div>
              <div className="col-md-4 text-center">
                <h5>amount needed to reach 20% goal</h5>
                <h1><small>$</small>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(i.amount_to_reach.toFixed(2))}</h1>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
              {pcvLinks.length>0 && pcvLinks.map((link,i)=>{
                  return (
                    <a
                      href={link.pcv_i_link}
                      className={i===0 ? 'button dark outline w-200 mx-3 my-1' : 'button dark w-200 mx-3 my-1'}
                      key={i}
                      target={link.pcv_i_open_in_new_tab ? '_blank' : '_self'}
                      rel={link.pcv_i_open_in_new_tab ? 'noopener noreferrer' : ''}
                    >{link.pcv_i_title}</a>
                  )
                })
              }
            </div>
          </div>
        )
      }else{
        return null
      }
    }else{
      return null;
    }
  }


  //
  //
  const _box4 = () =>{
    return(
      items && items.dermDollar && items.dermDollar.derm_details ?
        <div className="box box4 my-5">
          <h4 className="plain">Recent point activity</h4>
          <div className="ordersTable">
            <div className="row row-eq-height head no-gutters d-none d-md-flex">
              <div className="col-md-3">Date</div>
              <div className="col-md-6">Point activity</div>
              <div className="col-md-3 text-md-right">Dollars earned</div>
            </div>
            {items.dermDollar.derm_details.map((item,i)=>{
              return(
                <div className="row row-eq-height body no-gutters" key={i}>
                  <div className="col-md-3">{dayjs(item.trans_date).format('MM/DD/YYYY')}</div>
                  <div className="col-md-6">{item.trans_desc}</div>
                  <div className="col-md-3 text-md-right">${item.points_earned} Derm Dollars</div>
                </div>
              )
            })}
          </div>
          <div className="text-center d-flex justify-content-center mt-4">
            <a href={ViewBusinessRecordCard} className="button blank w-200" target={'_blank'}>view business record card</a>
          </div>
        </div>
      : null
    )
  }


  //
  //
  const _box5 = () =>{
      console.log(smcLinks, smcLinks.length)
    if(smcLinks.length>0){
      return(
        <div className="box box3 my-5 d-flex flex-column justify-content-center align-items-center">
          <h4 className="my-4">Social Media Collective</h4>
          <p className="text-center px-5"><small>
            As part of our Dermalogica Loyalty program you have exclusive assess to our social media collective to help maintain your Salon’s social media account. View our social media calendar and ready to download assets and captions for easy posting!
          </small></p>
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center my-4">
            {smcLinks.map((link,i)=>{
                return (
                  <a
                    href={link.sml_i_link}
                    className={i===0 ? 'button dark outline w-200 mx-3 my-1' : 'button dark w-200 mx-3 my-1'}
                    key={i}
                    target={link.sml_i_open_in_new_tab ? '_blank' : '_self'}
                    rel={link.sml_i_open_in_new_tab ? 'noopener noreferrer' : ''}
                  >{link.sml_i_title}</a>
                )
              })
            }
          </div>
        </div>
      )
    }
  }


    // console.log("auth.user.owner_rights==='Y'", auth.user.owner_rights==='Y')
    // console.log("auth.user.apps && auth.user.apps.length>0 && auth.user.apps[0].app_name==='BRC' && auth.user.apps[0].app_access==='Y'", auth.user.apps && auth.user.apps.length>0 && auth.user.apps[0].app_name==='BRC' && auth.user.apps[0].app_access==='Y')
  //
  //
  return (
    <Fragment>
      <Helmet>
        <title>{ SITENAME }</title>
      </Helmet>

      <div className="dashboard">

        <QueueAnim type={['right', 'left']} className="welcome">
          <div key="1" className="container">
            <h5>My dashboard</h5>
            <h2 className="pt-0 pb-0">welcome back, {auth.user.first_name}</h2>
            {/* <Link to={'/my-account'} className="button rounuded outline white py-2">update profile</Link> */}
          </div>
        </QueueAnim>

        <div className="main">
          <div className="boxes">
            <QueueAnim type={['bottom', 'top']} className="container">

              {/* <div key="0">{_checkRole([5,6], auth.role.id) && items && items.loyaltyinfo && items.loyaltyinfo[0] && _box1()}</div>
              <div key="1">{auth.user.owner_rights==='Y' || auth.user.apps && auth.user.apps.length>0 && auth.user.apps[0].app_name==='BRC' && auth.user.apps[0].app_access==='Y' ? _box5() : null}</div>
              {auth.user.is_buyer==='Y' && auth.user.account_no !== 0 ? <div key="2">{_box2()}</div> : null}
              {auth.user.is_buyer==='Y' && auth.user.account_no !== 0 ? <div key="3">{_box3()}</div> : null}
              {auth.user.is_buyer==='Y' && auth.user.account_no !== 0 ? <div key="4">{_box4()}</div> : null}
              {auth.user.account_no !== 0 && <BlockPromotions key="5"/>}
              {auth.user.account_no !== 0 && <BlockPrograms key="5"/>}
              {auth.user.account_no !== 0 && <BlockAnnouncements key="6"/>} */}

            </QueueAnim>
          </div>
        </div>

      </div>

      {loading && <Loading/>}

    </Fragment>
  );

}


// <div key="1">{_box1()}</div>
// <div key="2">{_box2()}</div>
// <div key="3">{_box3()}</div>
// <div key="4">{_box4()}</div>
