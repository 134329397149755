import React, { Fragment, useState, useEffect, useRef } from 'react'

// custom style
import './style.scss';

// components
import Header from '../Header'
import Footer from '../Footer'
import Sidebar from '../Sidebar';

export default function Layout(props) {

  const [isSticky, setSticky] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);


  //
  //
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };


  //
  //
  const scrollTop = () =>{
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  };


  //
  //
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);


  return (
    <Fragment>
      <Header state={isSticky ? true : false} open={(e)=> setOpen(e) }/>
      <div className={`page-wrapper${isSticky ? ' sticky' : ''} ${isOpen ? ' open' : ''}`} ref={ref}>
      {
        props.sidebar ?
          <div className="innerLayout">
            <Sidebar/>
            {props.children}
          </div>
        :
          props.children
      }
      </div>
      <Footer open={isOpen}/>
      <div className="toUp" onClick={()=>scrollTop()}></div>
    </Fragment>
  );
};
