import React from 'react';

// custom style
import './style.scss';


//
//
export default function Loading(props){

  return (
    <div className="loading">
      <div>
        <i></i>
      </div>
    </div>
  )

}
