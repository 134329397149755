import React from 'react';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react';

// services
import {store, persistor} from './controllers/redux/Store'
import Router from './controllers/Router';
import Authentication from './controllers/Authentication';

// compnents
// import Preloader from './components/Preloader';


function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={'Loading...'} persistor={persistor}>
        <Authentication>
          <Router/>
        </Authentication>
      </PersistGate>
    </Provider>
  );
}

export default App;
