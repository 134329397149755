const init = (state = {}, action) => {
  switch(action.type){
    case "INITSTATUS":
      return {
        ...state,
        data: action.data
      }
    case "INITUPDATE":
      return {
        ...state,
        data: action.data || state.data
      }
    default:
      return state
  }
}

export default init;
