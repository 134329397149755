import React, { Fragment, useState, useEffect } from 'react';
import {useSelector} from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

// controllers
import { _checkRole } from '../../controllers/Roles'

// variables
import { ProUrl, LogoutUrl, ViewBusinessRecordCard, navItems } from '../../configuration.js';

// custom style
import './style.scss';

// images
import logo from '../../assets/images/dermalogica-logo-dark.svg';
import iconHelp from '../../assets/images/question.svg';


//
//
export default function Header(props) {

  let location = useLocation();
  const init = useSelector(state => state.init);
  const auth = useSelector(state => state.auth);
  const [ navicon, setNavicon ] = useState(false);
  const [ currentPage, setCurrentPage ] = useState('');


  //
  //
  // const _itemsMobile = () =>{
  //   if(navItems){
  //     return navItems.map((item,i)=>{
  //       return(
  //         <li key={i}>
  //           <Link to={item.link} onClick={ navicon ? _open : null} className={currentPage===item.link ? 'active' : ''}>
  //             {item.title}<i></i>
  //           </Link>
  //         </li>)
  //     })
  //   }
  // }
  //
  //
  const _itemsMobile = () =>{
    if(navItems){
      return navItems.map((item,i)=>{
        let isVisible = _checkRole(item.role, auth.role.id)
        if(item.or && item.or==='salonservices' && auth.user.access_code){
          isVisible = auth.user.access_code.includes(item.or) || false
        }
        if(item.or && item.or==='owner_rights'){
        console.log('Manage employees',isVisible, auth.user.owner_rights==='Y')
          isVisible = auth.user.owner_rights==='Y' || isVisible ? true : false
        }
        return(
          isVisible ?
          <li key={i}>
            <Link to={item.link} onClick={ navicon ? _open : null} className={currentPage===item.link ? 'active' : ''}>
              {item.title}<i></i>
            </Link>
          </li>
          : null
        )
      })
    }
  }


  //
  //
  const _items = () =>{
    if(init && init.data && init.data.navigation){
      return init.data.navigation.map((item,i)=>{
        const segment_array = item.url.replace(/\/$/, '');
        const last_segment = segment_array.substr(segment_array.lastIndexOf('/') + 1);
        if(item.target){
          return(<li key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}<i></i></a></li>)
        }else{
          return(<li key={i}><Link to={last_segment} onClick={ navicon ? _open : null} className={currentPage==='/'+last_segment ? 'active' : ''}>{item.title}<i></i></Link></li>)
        }
      })
    }
  }


  //
  //
  useEffect(()=>{
    setCurrentPage(location.pathname);
  },[location])


  //
  //
  useEffect(()=>{
    if(navicon){
      document.body.classList.add('fixed');
    }else{
      document.body.classList.remove('fixed');
    }
  },[navicon]);


  //
  //
  const _open = () => {
    props.open(!navicon);
    setNavicon(!navicon);
  }


  //
  //
  return (
    <Fragment>

    <header className="header">
      <div className="container" id="headerContainer">

        <a href={ProUrl} className="logo" aria-label="Front">
          <img src={logo} alt="Dermalogica PRO" className="light"/>
        </a>

        <nav className={`navigation  d-flex justify-content-end ${navicon ? ' active' : ''}`}>
          {/* <ul className="mobile">
            {_itemsMobile()}
            {auth.user.apps && auth.user.apps.length>0 && auth.user.apps[0].app_name==='BRC' && auth.user.apps[0].app_access==='Y' ?
            <li>
              <a href={ViewBusinessRecordCard} target="_blank" rel="noopener noreferrer">
                Business record card
              </a>
            </li>
            : null }
          </ul>
          <ul>
            {_items()}
          </ul> */}
          <ul className="userMenu">
            {/* <li className="help"><a href={ProUrl+'/faq/'} target="_blank" rel="noopener noreferrer"><img src={iconHelp} alt=""/></a></li>
            <li><Link to={'/my-account'} onClick={ navicon ? _open : null}>Account</Link></li> */}
            {/* <li className="border-left"><a href={LogoutUrl} rel="noopener noreferrer">Log out</a></li> */}
            <li className=""><a href={LogoutUrl} rel="noopener noreferrer">Log out</a></li>
            {/* <li className="border-left"><Link to={'/'} onClick={ navicon ? _open : null}>Log out</Link></li> */}

          </ul>
        </nav>

        <div className="search">
          <a href={'#'}>
            <img src={ProUrl+'/wp-content/themes/pro/front/dist/assets/img/icons/search.svg'} alt="Logo" className="light"/>
          </a>
        </div>

        <button onClick={()=> _open() } className={`navicon${navicon ? ' active' : ''}`}>
          <i></i>
          <i></i>
          <i></i>
        </button>

      </div>
    </header>
    </Fragment>
  );
};
